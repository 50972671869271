<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="会员姓名：">
                    <el-input size="small" placeholder="请输入会员姓名" v-model="searchForm.name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <el-input size="small" placeholder="请输入联系电话" v-model="searchForm.u_mobile"></el-input>
                </el-form-item>
                <el-form-item label="订单编号：">
                    <el-input size="small" placeholder="请输入订单编号" v-model="searchForm.order_no"></el-input>
                </el-form-item>
                <el-form-item label="消费卡名称：">
                    <el-input size="small" placeholder="请输入消费卡名称" v-model="searchForm.consumer_card_name"></el-input>
                </el-form-item>
                <el-form-item label="支付时间：">
                    <el-date-picker size="small" v-model="time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="order_no" label="订单编号" align="center" min-width="120"></el-table-column>
                <el-table-column prop="name" label="会员姓名" align="center" min-width="110"></el-table-column>
                <el-table-column prop="u_mobile" label="联系电话" align="center" min-width="110"></el-table-column>
                <el-table-column prop="consumer_card_name" label="消费卡名称" align="center" min-width="140"></el-table-column>
                <el-table-column prop="consumer_card_amount" label="消费卡金额" align="center" min-width="110"></el-table-column>
                <el-table-column prop="amount" label="支付金额" align="center" min-width="110"></el-table-column>
                <el-table-column prop="consumer_card_balance" label="剩余金额" align="center" min-width="110"></el-table-column>
                <el-table-column prop="create_time" label="支付时间" align="center" min-width="110">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120" fixed="right" min-width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleDetail(scope.row)" size="small">消费明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>

            <el-dialog title="消费明细" :visible.sync="dialogVisible" width="800px">
                <el-descriptions contentStyle="font-weight: bold;" labelStyle="font-weight: bold;">
                    <el-descriptions-item label="消费卡金额">{{ log_price.consumer_card_amount }}</el-descriptions-item>
                    <el-descriptions-item label="剩余金额">{{ log_price.consumer_card_balance }}</el-descriptions-item>
                    <el-descriptions-item label="花费金额">{{ (log_price.consumer_card_amount -
                        log_price.consumer_card_balance).toFixed(2) }}</el-descriptions-item>
                </el-descriptions>
                <!-- 列表 -->
                <el-table :data="log_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="create_time" label="支付/退回时间" align="center">
                        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                    </el-table-column>
                    <el-table-column prop="order_no" label="支付订单" align="center"></el-table-column>
                    <el-table-column prop="type" label="消费状态" align="center" width="120">
                        <template v-slot="{ row }">{{ row.type == -1 ? '已使用' : '已退回' }}</template>
                    </el-table-column>
                    <el-table-column prop="amount" label="消费卡金额" align="center">
                        <template v-slot="{ row }">{{ row.type == -1 ? '-' : '+' }}￥{{ row.amount }}</template>
                    </el-table-column>
                </el-table>
                <!-- 翻页 -->
                <Paging :total="log_total" :page="log_form.page" :pageNum="log_form.rows" @updatePageNum="updateData2">
                </Paging>
            </el-dialog>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                name: '',
                u_mobile: '',
                order_no: '',
                consumer_card_name: '',
                start_time: '',
                end_time: ''
            },
            time: '',
            dialogVisible: false,
            log_form: {
                page: 1,
                rows: 10,
                id: ''
            },
            log_total: 0,
            log_list: [],
            log_price: {
                consumer_card_amount: '0.00',
                consumer_card_balance: '0.00'
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                name: '',
                u_mobile: '',
                order_no: '',
                consumer_card_name: '',
                start_time: '',
                end_time: ''
            };
            this.time = ''
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        updateData2 (val, status) {
            if (status == 0) {
                this.log_form.rows = val;
                this.getLogList();
            } else {
                this.log_form.page = val;
                this.getLogList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            if (this.time) {
                searchForm.start_time = this.time[0] / 1000
                searchForm.end_time = this.time[1] / 1000
            }
            this.$axios.post(this.$api.repair.marketing.ConsumerCardOrder, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDetail (row) {
            this.log_form = {
                page: 1,
                rows: 10,
                id: row.id
            }
            this.dialogVisible = true
            this.log_price = {
                consumer_card_amount: row.consumer_card_amount,
                consumer_card_balance: row.consumer_card_balance
            }
            this.getLogList()
        },
        getLogList () {
            this.$axios.post(this.$api.repair.marketing.ConsumerCardLogList, this.log_form).then(res => {
                if (res.code == 0) {
                    this.log_list = res.result.list;
                    this.log_total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}

.detail {
    width: 600px;

    /deep/img {
        width: 100%;
        height: auto;
    }
}
</style>
  